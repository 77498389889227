import React from 'react';

import SEO from '../../components/seo';
import styles from './job.module.css';

const JobPage = () => {
  return (
    <>
      <SEO title="Business analyst" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Business analyst</h1>

          <p className={styles.text}>
            Baví tě komunikace? Máš analytické uvažování? Jsi zkušený v IT a data jsou pro tebe denním chlebem?
            Chceš pracovat pro rychle rostoucí firmu se skvělým týmem?
            Pokud tvoje odpověď byla Ano, možná hledáme právě tebe.
          </p>

          <h3 className={styles.textSubHeader}>
            Něco o nás
          </h3>
          <p className={styles.text}>
            NutritionPro je úspěšný a jeden z nejrychleji se rozvíjejících startupů v oblasti Foodtech.
            Vytvořili jsme algoritmus, který dle tělesných údajů vytváří jídelníčky na míru. Díky kompletnímu servisu jídlo uvaříme, navážíme a také dovezeme.
          </p>

          <h3 className={styles.textSubHeader}>
            Co tě u nás čeká?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Provádění průzkumu podnikových procesů
            </li>
            <li className={styles.newList}>
              Shromažďování výchozích informací z podniku
            </li>
            <li className={styles.newList}>
              Komunikace s účastníky procesu
            </li>
            <li className={styles.newList}>
              Identifikace překážek a dopadů na systémy
            </li>
            <li className={styles.newList}>
              Identifikace softwarových požadavků
            </li>
            <li className={styles.newList}>
              Tvorba odborných úloh pro vývoj s jasným a srozumitelným výsledkem realizace
            </li>
            <li className={styles.newList}>
              Analýza současných podnikových procesů, návrh optimálního řešení ve spolupráci s týmem a s ohledem na jejich schopnosti a specifika.
            </li>
            <li className={styles.newList}>
              Interní a externí komunikace s oddělením ohledně údržby a vývoje softwaru.
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
            Jaké zkušenosti a dovednosti bys měl mít?
          </h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Dobré komunikační schopnosti
            </li>
            <li className={styles.newList}>
              Odolnost vůči stresu
            </li>
            <li className={styles.newList}>
              Plánovací a organizační typ
            </li>
            <li className={styles.newList}>
              Analytické myšlení
            </li>
            <li className={styles.newList}>
              Schopnost práce samostatně i v týmu
            </li>
            <li className={styles.newList}>
              Prokazatelné zkušenosti na pozici business analytika nejméně 3 roky.
            </li>
            <li className={styles.newList}>
              Porozumění a pochopení architektury IT, základní znalosti systémové analýzy.
            </li>
            <li className={styles.newList}>
              Zkušenosti práce s daty UI/UX, pochopení, popsání dané situace a následné shrnutí, jak by to mělo vypadat.
            </li>
            <li className={styles.newList}>
              Zkušenost s nástroji typu Jira
            </li>
            <li className={styles.newList}>
              Znalosti s dokumentováním, především obchodní, funkční a technické požadavky.
            </li>
            <li className={styles.newList}>
              Chápání životního cyklu softwarového vývoje
            </li>
            <li className={styles.newList}>
              Výhodou je, pokud používáš Markdown.
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co za to dostaneš?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Práci ve stabilní a perspektivní firmě
            </li>
            <li className={styles.newList}>
              Motivující finanční ohodnocení
            </li>
            <li className={styles.newList}>
              Plně hrazené vzdělávací kurzy
            </li>
            <li className={styles.newList}>
              Firemní obědy zdarma
            </li>
            <li className={styles.newList}>
              Plně hrazenou multisport kartu
            </li>
            <li className={styles.newList}>
              Skvělou kávu
            </li>
            <li className={styles.newList}>
              Možnost částečné práce z domova
            </li>
            <li className={styles.newList}>
              Moderní kanceláře v srdci Karlína
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <p className={styles.text}>Kontakty:</p>
          <a className={styles.text} href="mailto:jobs@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          <br />
          {/* <a className={styles.text} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_Sales" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
